<template>
  <div class="grid grid-cols-11 divide-y footer bg-transparent">
    <div class="grid grid-cols-1 lg:grid-cols-9 col-start-2 col-span-9">
      <div class="col-span-1 lg:col-start-1 lg:col-span-2">
        <img src="../assets/layout/color.png" class="Logo-Exval-color" />
        <p class="footer_P">
          Exval est une société spécialisée dans le Smart Data et l’Intelligent
          Data pour les marchés immobiliers.
        </p>
      </div>

      <div class="col-span-1 lg:col-start-4 lg:col-span-1">
        <p class="footer_P">
          <br />
          <router-link to="/nos-outils">Nos outils</router-link> <br />
          <router-link to="/tarifs">Nos tarifs</router-link> <br />
          <a href="https://exval.app/login" target="_blank">Se connecter</a>
          <router-link to="/aide" class="hidden">Aide</router-link> <br />
        </p>
      </div>

      <div class="col-span-1 lg:col-start-6 lg:col-span-1">
        <!--p class="footer_P">
          <br/>
          S’inscrire <br/>
          Se connecter <br/>
          S’abonner <br/>
        </p-->
      </div>

      <div class="col-span-1 lg:col-start-8 lg:col-span-1">
        <!--p class="footer_P">
          <br/>
          Mentions légales <br/>
          CGU / CGV <br/>
          Nous contacter <br/>
        </p-->
      </div>
    </div>

    <!-- Footer bottom -->
    <div class="grid grid-cols-12 col-start-2 col-span-9 footer_bottom">
      <div class="col-span-12 lg:col-start-1 lg:col-span-1">
        <router-link class="footer_bottom_P" to="/confidentialite"
          >Confidentialité</router-link
        >
      </div>
      <div class="col-span-12 lg:pl-10 lg:col-start-2 lg:col-span-1">
        <router-link class="footer_bottom_P text-left lg:text-center" to="/cgu"
          >CGU</router-link
        >
      </div>
      <div class="col-span-12 lg:col-start-3 lg:col-span-2 hidden">
        <router-link class="footer_bottom_P" to="/mentions-legales"
          >Mentions légales</router-link
        >
      </div>
      <div class="col-span-12 lg:col-start-10 lg:col-span-3">
        <p class="footer_bottom lg:pt-32 text-left lg:text-right">
          Copyright by 2021 Exval, France
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  padding: 0px 0 100px 0;
  margin-top: 100px;
}

.footer_P {
  margin: 24px 20px 59px 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}

.footer_bottom {
  padding-top: 2px;
}

.footer_bottom_P {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
</style>